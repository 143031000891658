import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APP_CONSTANT, COOKIE_GROUP } from '../helperComponents/Constants'
import {
  AMPLITUDE_COOKIE_MAP,
  AMPLITUDE_LINK_NAME,
  AMPLITUDE_MOBILE_APP_COOKIES_UI_NAME,
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
  AMPLITUDE_TOGGLE_STATUS,
} from '../helperComponents/Analytics/AnalyticsConstants'
import { utils } from '../helperComponents/Utils'
import HyperLink from '../components/HyperLink'
import { useTranslation } from 'react-i18next'
import FeatureToggle from '../manageData/FeatureToggle'
import { useLocation, useNavigate } from 'react-router-dom'
import TurnOnOffDialog from '../dialog/TurnOnOffDialog'
import { setActivePage } from '../../redux/actions/PageLoadActions'
import { analytics } from '../helperComponents/Analytics/Analytics'
import { setDialogIsOpen } from '../../redux/actions/UIActions'
import {replaceBrand} from '../helperComponents/contentUtils'

export function MACTile(props) {
  const { t } = useTranslation()
  const [displayPopup, setDisplayPopup] = useState(false)
  const groupName = props.groupName
  const dispatch = useDispatch()
  const isDividerLine = !!props.isDividerLine
  const mobileAppCookiesData = useSelector((state) => state.mobileAppCookies)
  let data
  if (props.groupName === COOKIE_GROUP.PERFORMANCE) {
    data = mobileAppCookiesData[0][1].groupPrivacyData[0]
  } else {
    data = mobileAppCookiesData[0][1].groupPrivacyData[1]
  }
  const [isToggleOn, setIsToggleOn] = useState(data.statusCode === 'Y')

  useEffect(() => {
    if (displayPopup) dispatch(setDialogIsOpen(true))
    else dispatch(setDialogIsOpen(false))
  }, [displayPopup])

  function onToggle() {
    analytics.fireToggleTappedEvent(
      AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES,
      AMPLITUDE_COOKIE_MAP.get(props.groupName),
      isToggleOn ? AMPLITUDE_TOGGLE_STATUS.TURN_ON : AMPLITUDE_TOGGLE_STATUS.TURN_OFF,
    )
    setDisplayPopup(true)
  }

  function closePopup() {
    analytics.fireCancelTappedEvent(
      isToggleOn ? AMPLITUDE_SCREEN_NAME.TURN_OFF : AMPLITUDE_SCREEN_NAME.TURN_ON,
      AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES,
      AMPLITUDE_COOKIE_MAP.get(props.groupName),
    )
    setDisplayPopup(false)
  }

  function exitPopup() {
    analytics.firePageExitTappedEvent(
      isToggleOn ? AMPLITUDE_SCREEN_NAME.TURN_OFF : AMPLITUDE_SCREEN_NAME.TURN_ON,
      AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES,
      AMPLITUDE_COOKIE_MAP.get(props.groupName),
    )
    setDisplayPopup(false)
  }

  function handleDialogConfirmation() {
    const newToggleStatus = !isToggleOn
    setIsToggleOn(newToggleStatus)

    if (newToggleStatus) {
      analytics.fireToggleConfirmationTappedEvent(
        AMPLITUDE_SCREEN_NAME.TURN_ON,
        AMPLITUDE_COOKIE_MAP.get(props.groupName),
      )
      utils.dispatchSavePrivacy(
        dispatch,
        mobileAppCookiesData[0][1],
        null,
        'Y',
        null,
        APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE,
        groupName,
      )
    } else {
      analytics.fireToggleConfirmationTappedEvent(
        AMPLITUDE_SCREEN_NAME.TURN_OFF,
        AMPLITUDE_COOKIE_MAP.get(props.groupName),
      )
      utils.dispatchSavePrivacy(
        dispatch,
        mobileAppCookiesData[0][1],
        null,
        'N',
        null,
        APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE,
        groupName,
      )
    }

    setDisplayPopup(false)
  }

  function renderPopup() {
    if (displayPopup) {
      const contentKey = isToggleOn
        ? `lbl_turnOff_popup_text_MAC_${groupName}_N`
        : `lbl_turnOn_popup_text_MAC_${groupName}_Y`
      return (
        <TurnOnOffDialog
          open={true}
          isTurnOn={isToggleOn}
          subtitle={t(`lbl_mac_cookies_${groupName}`)}
          content={replaceBrand(t(contentKey))}
          onPrimaryButtonCliked={handleDialogConfirmation}
          onSecondaryButtonCliked={closePopup}
          onCloseButtonCliked={exitPopup}
        />
      )
    }
  }

  return (
    <div className={'mac-page__mac-tile'}>
      <div className={'mac-page__mac-tile__name-and-toggle'}>
        <div className={'mac-page__mac-tile__sub-header'}>{t(`lbl_mac_${groupName}`)}</div>
        <FeatureToggle value={isToggleOn} onToggle={onToggle} />
      </div>

      <div className={'mac-page__mac-tile__description_text'}>{t(`lbl_mac_${groupName}_desc`)}</div>
      <p />
      {!utils.errors.includes(data.timestamp) && (
        <div className="mac-page__mac-tile__timestamp">{utils.formatDate(data.timestamp, null, null)}</div>
      )}
      {isDividerLine && <hr className="fmc-divider--horizontal mac-page__mac-tile__divider" />}
      {renderPopup()}
    </div>
  )
}

export default function MobileAppCookies() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activePage = useLocation().pathname
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const mobileAppCookiesData = useSelector((state) => state.mobileAppCookies)

  function getRedirectUrl(countryCode) {
    switch (countryCode) {
      case 'TUR':
        return t('lbl_cookie_policy_link_turkey_url');
      case 'ARE':
        return APP_CONSTANT.CONFIG.BRAND_CODE === 'L' ? t('lbl_lincolnway_privacy_notice_ARE_url') : t('lbl_fordpass_privacy_notice_ARE_url');
      default:
        return t('lbl_cookie_policy_link_url');
    }
  }
  
  
  const redirectUrl = getRedirectUrl(APP_CONSTANT.CONFIG.COUNTRY_CODE || 'DEFAULT');

  useEffect(() => {
    dispatch(setActivePage(activePage))
    analytics.firePageViewEvent(AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES, getMobileAppCookieConsentStatuses())
  }, [])

  function getMobileAppCookieConsentStatuses() {
    const data = mobileAppCookiesData[0][1].groupPrivacyData
    return data.map(({ groupName, statusCode }) => {
      const uiName =
        groupName === COOKIE_GROUP.PERFORMANCE
          ? AMPLITUDE_MOBILE_APP_COOKIES_UI_NAME.MOBILE_APP_PERFORMANCE_COOKIE
          : AMPLITUDE_MOBILE_APP_COOKIES_UI_NAME.MOBILE_APP_ANALYTICS_COOKIE
      return { uiName, statusCode }
    })
  }

  function onClickBack(runningBlockName) {
    analytics.fireGoBackTappedEvent(
      AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES,
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      runningBlockName,
    )
    navigate(-1)
  }

  function onLearnMoreClicked() {
    analytics.fireLinkTappedEvent(
      AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES,
      AMPLITUDE_LINK_NAME.COOKIE_POLICY_LINK,
    )
  }

  return (
    <div className="mac-page">
      <div className="mac-page__header">
        <button
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          className="mac-page__go-back-cta"
          onClick={() => onClickBack(AMPLITUDE_RUNNING_BLOCK_NAME.HEADER)}
        >
          <span className="fds-icon fds-icon fds-icon--offset-left fds-font--ford-icons__chevron-left" />
          {t('lbl_go_back')}
        </button>
        <div className={'mac-page__title'}>{t('lbl_Mobile App Cookies')}</div>
      </div>

      <div className="mac-page__content">
        <div className={'mac-page__desc-text'}>{t('lbl_mobile_app_cookies_para')}</div>
        <MACTile isDividerLine={true} groupName={COOKIE_GROUP.PERFORMANCE} />
        <MACTile groupName={COOKIE_GROUP.ANALYTICS} />

        <div className="mac-page__footer">
          {t('lbl_analytics_link')}
          <HyperLink
            className="mac-page__footer-link"
            text={t('lbl_analytics_link_text')}
            onClick={onLearnMoreClicked}
            redirectUrl={redirectUrl}
          />
        </div>

        <div className={'mac-page__go-back-button'}>
          <button
            className="fmc-button"
            aria-hidden={isDialogOpen}
            tabIndex={utils.getTabIndex(isDialogOpen)}
            onClick={() => {
              onClickBack(AMPLITUDE_RUNNING_BLOCK_NAME.FOOTER)
            }}
          >
            {t('lbl_go_back')}
          </button>
        </div>
      </div>
    </div>
  )
}
