import {APPLICATION_PATH, COOKIE_GROUP} from '../Constants'

export const AMPLITUDE_SCREEN_NAME = Object.freeze({
  PRIVACY_LANDING_PAGE: 'Privacy Landing Page',
  MANAGE_DATA_WITH_FORD: 'Manage Data with Ford',
  MANAGE_DATA_WITH_THIRD_PARTIES: 'Manage Data with Third Parties',
  MOBILE_APP_COOKIES: 'Mobile App Cookies',
  TURN_ON: 'Turn On',
  TURN_OFF: 'Turn Off',
  LEARN_MORE: 'Learn More',
  UNLINK_ACCOUNT: 'Unlink Account',
  UNLINK: 'Unlink',
})

export const AMPLITUDE_SCREENNAME_MAP = new Map([
  [APPLICATION_PATH.LANDING, AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE],
  [APPLICATION_PATH.MANAGE_DATA_WITH_FORD, AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_FORD],
  [APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES, AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_THIRD_PARTIES],
  [APPLICATION_PATH.MOBILE_APP_COOKIES, AMPLITUDE_SCREEN_NAME.MOBILE_APP_COOKIES],
])

export const AMPLITUDE_COOKIE_MAP = new Map([
  [COOKIE_GROUP.PERFORMANCE, 'Performance Cookies'],
  [COOKIE_GROUP.ANALYTICS, 'Analytics Cookies'],
])

export const AMPLITUDE_EVENT_NAME = Object.freeze({
  LINK_TAPPED: 'privacy link tapped',
  SET_PREFERENCES_TAPPED: 'set preferences tapped',
  MOBILE_APP_COOKIES_TAPPED: 'mobile app cookies tapped',
  PRIVACY_VEHICLE_SELECTED: 'privacy vehicle selected',
  TOGGLE_TAPPED: 'toggle tapped',
  TOGGLE_CONFIRMATION_TAPPED: 'toggle confirmation tapped',
  CANCEL_TAPPED: 'cancel tapped',
  PAGE_EXIT_TAPPED: 'page exit tapped',
  PRIVACY_LEARN_MORE_TAPPED: 'privacy learn more tapped',
  LEARN_MORE_ACCORDION_TAPPED: 'learn more accordion tapped',
  GO_BACK_TAPPED: 'go back tapped',
  EXPAND_TAPPED: 'expand tapped',
  COLLAPSE_TAPPED: 'collapse tapped',
  UNLINK_THIRD_PARTIES_TAPPED: 'unlink third parties tapped',
  UNLINK_ACCOUNT_TAPPED: 'unlink account tapped',
  PRIVACY_UNLINK_TAPPED: 'privacy unlink tapped',
  PRIVACY_UNLINK_COMPLETED: 'privacy unlink completed',
  PRIVACY_PAGE_VIEWED: 'privacy page viewed',
  GOOGLE_PRIVACY_POLICY_TAPPED: 'google privacy policy tapped',
})

export const AMPLITUDE_LINK_NAME = Object.freeze({
  FORDPASS_PRIVACY_POLICY_LINK: 'Fordpass Privacy Policy link',
  PRIVACY_POLICY_AND_DISCLAIMER_LINK: 'Privacy Policy And Disclaimer link',
  PRIVACY_NOTICES_LINK: 'Privacy Notices link',
  FORDPASS_PRIVACY_NOTICES_LINK: 'Fordpass Privacy Notice link',
  YOUR_PRIVACY_CHOICE_LINK: 'Your Privacy Choice link',
  COOKIE_POLICY_LINK: 'Cookie Policy link',
  GOOGLE_POLICY_LINK: 'Google Policy link',
  TERMS_AND_PRIVACY_POLICY_LINK: 'Terms and Privacy Policy link',
  CALIFORNIA_NOTICE_AND_PRIVACY_DISCLOSURE: 'California Notice and Privacy Disclosure link',
  FORD_US_PRIVACY_NOTICE: 'Ford US Privacy Notice link',
  CONNECTED_VEHICLE_PRIVACY_NOTICE: 'Connected Vehicle Privacy Notice link',
  CONNECTED_VEHICLE_PRIVACY_POLICY: 'Connected Vehicle Privacy Policy link',
  TERMS_AND_CONDITIONS: 'Terms and Conditions link',
})

export const AMPLITUDE_SECTION_NAME = Object.freeze({
  MANAGE_DATA_WITH_FORD: 'Manage Data with Ford',
  MANAGE_DATA_WITH_THIRD_PARTIES: 'Manage Data with Third Parties',
})

export const AMPLITUDE_MOBILE_APP_COOKIES_UI_NAME = Object.freeze({
  MOBILE_APP_PERFORMANCE_COOKIE: 'mobileAppPerformanceCookie',
  MOBILE_APP_ANALYTICS_COOKIE: 'mobileAppAnalyticsCookie',
})

export const AMPLITUDE_TOGGLE_STATUS = Object.freeze({
  TURN_ON: 'Turn On',
  TURN_OFF: 'Turn Off',
})

export const AMPLITUDE_RUNNING_BLOCK_NAME = Object.freeze({
  HEADER: 'header',
  FOOTER: 'footer',
})
